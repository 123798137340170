import styled from 'styled-components';

export const Menu = styled.main`
  padding: 15px;
  max-width: 600px;
  margin: 0 auto;

  .menu-title {
    margin-top: 25px;
    margin-bottom: 45px;
  }

  .menu-subtitle {
    text-align: center;

    margin-top: -25px;
    margin-bottom: 35px;
  }

  .menu-sizes {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 5px;
    margin-top: -25px;

    p {
      background-color: #ea3437;
      padding: 2px;
      color: #ffffff;
      text-align: center;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 3px;
      width: 80px;
    }

    p:first-of-type {
      margin-right: 5px;
    }
  }
`;

export const Item = styled.div`
  margin-bottom: 40px;
  border-left: 2px solid #ea3437;
  padding-left: 10px;

  .item-separator {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
  }

  .item-title {
    flex-grow: 1;
  }

  .item-price-wrapper {
    display: flex;

    h4 {
      text-align: center;
      width: 80px;
    }
  }

  .item-desc {
    color: #555;
    margin-top: 5px;
    font-size: 0.875rem;
  }
`;

export const BackToTop = styled.div`
  text-align: center;

  a {
    color: #414192;
    margin: 5px;
  }
`;
